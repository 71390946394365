import React, { useEffect, useState } from 'react';
import TopCrumb from '../components/TopCrumb';
import questionApi from '../services/questionActions';
import { Result, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkModuleCompletion } from '../services/helperFunc';
import table from '../images/table.png';

function Assessment() {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState('');
  const [completedModules, setCompletedModules] = useState('');
  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');

  const { module, user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const [retake, setRetake] = useState(false);

  const [notCompleted, setNotCompleted] = useState(false);
  const [completedModule, setCompletedModule] = useState('');
  // console.log('user', user);
  useEffect(() => {
    if (curModule) {
      const getCompletedModules = async () => {
        try {
          const res = await questionApi.getModuleCompletion();
          if (res) {
            setCompletedModules(res.data.data);
          }
        } catch (error) {
          if (error.response?.data.error) {
            message.error(error.response?.data.error);
          }
        }
      };
      getCompletedModules();
    }
  }, [curModule]);

  useEffect(() => {
    if (completedModules) {
      // module completion checker
      checkModuleCompletion(
        completedModules,
        setNotCompleted,
        setCompletedModule,
        module,
        setRetake,
        user
      );
    }
  }, [completedModules, module, curModule, user]);

  // console.log('completedModules', completedModules);

  useEffect(() => {
    if (module) {
      const getAssessmentData = async () => {
        try {
          const res = await questionApi.getAssessment(module?.id);
          if (res) {
            // console.log('res', res);
            setQuestions(res.data.data.sort((a, b) => a.id - b.id));
          }
        } catch (error) {
          // console.log('error', error);
          if (error.response?.data.error) {
            message.error(error.response?.data.error);
          }
        }
      };
      getAssessmentData();
    }
  }, [module, curModule]);
  // console.log('questions', questions)

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerSelection = (answerId) => {
    setSelectedAnswer(answerId);
  };

  // console.log('currentQuestionIndex', currentQuestionIndex, questions.length);

  const handleNextQuestion = () => {
    if (!selectedAnswer) {
      return message.error('Please select an answer.');
    }

    const currentQuestion = questions[currentQuestionIndex];
    const selectedAnswerObj = currentQuestion.answer.find(
      (answer) => answer.id === selectedAnswer
    );

    if (selectedAnswerObj.is_correct === '1') {
      // Answer is correct
      const currentQuestionIndexValue = currentQuestionIndex + 1;
      setCurrentQuestionIndex(currentQuestionIndexValue);
      setSelectedAnswer(null);

      // submit answerSubmission
      const body = {
        course_id: module.id,
        question_id: currentQuestion.id,
        answer_id: selectedAnswerObj.id,
        retake,
      };

      const submitAns = async () => {
        try {
          setLoading(true);
          const res = await questionApi.answerSubmitAction(body);
          if (res) {
            setLoading(false);
            // setCompletedModules(res.data.data);
          }
        } catch (error) {
          setLoading(false);
          if (error.response?.data.error) {
            message.error(error.response?.data.error);
          }
        }
      };
      submitAns();

      //  if currentQuestionIndexValue === questions.length (submit to user course status)

      if (currentQuestionIndexValue == questions.length) {
        let data = {
          course_id: module.id,
          type: 'assessment_status',
          retake,
        };

        const courseStatusUpdate = async () => {
          try {
            await questionApi.courseStatusAction(data);
          } catch (error) {
            // console.log('error', error);
            if (error.response?.data.error) {
              message.error(error.response?.data.error);
            }
          }
        };
        courseStatusUpdate();

        if (!retake) {
          message.success(
            'Congratulations! You have successfully completed this assessment. 1 CPD point will be accorded for this online module. Kindly check your email for the e-cert'
          );
        }

        setTimeout(() => {
          navigate(`/certificate?module=${curModule}`);
        }, 3000);
      }
    } else {
      // Display error popup or handle incorrect answer
      // You can show an error message or perform any desired action
      message.error('Incorrect answer. Please try again.');
    }
  };
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedAnswer(null);
    }
  };

  const renderQuestion = (question) => {
    if (question.includes('rnz')) {
      const questions = question.split('rnz');

      if (Number(curModule) === 7 && currentQuestionIndex == '4') {
        <img src={table} alt="table" />;
        const firstQuestion = questions.slice(0, 2);
        const thirdQeustions = questions
          .slice(3, questions.length)
          .map((each, idx) => {
            return <div key={idx}> {each}</div>;
          });
        return (
          <div>
            <div>{firstQuestion[0]} </div>
            <div className="mt-2">{firstQuestion[1]} </div>
            <img
              style={{ objectFit: 'contain', width: '90%' }}
              src={table}
              alt="table"
            />
            <div>{thirdQeustions}</div>
          </div>
        );
      } else {
        return questions.map((each, idx) => {
          return <div key={idx}> {each}</div>;
        });
      }
    } else {
      return question;
    }
  };

  // console.log('retake', retake);

  return (
    <div className="mt-4" style={{ overflowX: 'auto' }}>
      <TopCrumb module={module} type="Assessment" />
      <div className="margin-big-width">
        {completedModule ? (
          <Result status="success" title={completedModule} />
        ) : notCompleted ? (
          <Result status="warning" title={notCompleted} />
        ) : (
          questions.length > 0 &&
          currentQuestionIndex < questions.length && (
            <div>
              <div
                style={{ overflow: 'hidden' }}
                className="w-100 questions-bg my-5"
              >
                <div className="row">
                  <div className=" pos-rel">
                    <div className="question-text d-flex">
                      <div
                        className="question-tag align-self-start me-2 fw-bold"
                        style={{ minWidth: '50px' }}
                      >
                        Q{currentQuestionIndex + 1}
                      </div>
                      <div>
                        {renderQuestion(
                          questions[currentQuestionIndex].question
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="options" style={{ marginTop: '20px' }}>
                    {questions[currentQuestionIndex].answer
                      .sort((a, b) => a.id - b.id)
                      .map((answer) => (
                        <div key={answer.id} className="ms-3">
                          <label>
                            <div className="d-flex">
                              <input
                                className="align-self-start"
                                style={{ marginTop: '7px' }}
                                type="radio"
                                name="answer"
                                value={answer.id}
                                checked={selectedAnswer === answer.id}
                                onChange={() =>
                                  handleAnswerSelection(answer.id)
                                }
                              />
                              <div style={{ textAlign: 'start' }}>
                                {answer.answer}
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-center">
                <button
                  className="btn btn-radius btn-yellow-bg px-4 font-weight-boldest dark-blue-title btn-next"
                  onClick={handlePreviousQuestion}
                >
                  Previous{' '}
                  <i
                    className="spinner-next fa fa-spinner fa-spin"
                    style={{ display: 'none' }}
                  ></i>
                </button>
                <div className="mx-3"></div>
                <button
                  className="btn btn-radius btn-yellow-bg px-4 font-weight-boldest dark-blue-title btn-next"
                  onClick={handleNextQuestion}
                  disabled={loading}
                >
                  NEXT{' '}
                  <i
                    className="spinner-next fa fa-spinner fa-spin"
                    style={{ display: 'none' }}
                  ></i>
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Assessment;
