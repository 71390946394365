import React, { useEffect, useState } from 'react';
import TopCrumb from '../components/TopCrumb';
import questionApi from '../services/questionActions';
import { Result, message } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import table from '../images/table.png';

function AssessmentAnswer() {
  const [questions, setQuestions] = useState('');
  const [completedModules, setCompletedModules] = useState('');
  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');

  const { module } = useSelector((state) => state.user);

  const [completePrevious, setCompletePrevious] = useState(null);

  useEffect(() => {
    const getCompletedModules = async () => {
      try {
        const res = await questionApi.getModuleCompletion();
        if (res) {
          setCompletedModules(res.data.data);
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    getCompletedModules();
  }, [curModule]);

  useEffect(() => {
    if (curModule) {
      if (completedModules.length) {
        //
        const currentModuleId = module.id;

        const isAssessmentCompleted = completedModules.find(
          (ans) =>
            ans.course_id == currentModuleId && ans.assessment_status === '1'
        );
        if (isAssessmentCompleted) {
          return setCompletePrevious('');
        }

        // console.log('isAssessmentCompleted', isAssessmentCompleted);
        // check if assessment is completed for the current after complete video
        if (!isAssessmentCompleted) {
          return setCompletePrevious('Please complete the assessment.');
        }
      } else {
        return setCompletePrevious('Please complete the assessment.');
      }
    }
  }, [completedModules, module, curModule]);

  // console.log('completedModules', completedModules);

  useEffect(() => {
    if (module) {
      const getAssessmentData = async () => {
        try {
          const res = await questionApi.getAssessment(module?.id);
          if (res) {
            // console.log('res', res);
            setQuestions(res.data.data.sort((a, b) => a.id - b.id));
          }
        } catch (error) {
          // console.log('error', error);
          if (error.response?.data.error) {
            message.error(error.response?.data.error);
          }
        }
      };
      getAssessmentData();
    }
  }, [module, curModule]);
  // console.log('questions', questions);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // console.log('currentQuestionIndex', currentQuestionIndex, questions.length);

  const handleNextQuestion = () => {
    // Answer is correct
    const currentQuestionIndexValue = currentQuestionIndex + 1;
    setCurrentQuestionIndex(currentQuestionIndexValue);

    if (currentQuestionIndexValue == questions.length) {
      message.success('You have reviewed all the answers.');
      setCurrentQuestionIndex(0);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const renderQuestion = (question) => {
    if (question.includes('rnz')) {
      const questions = question.split('rnz');

      if (Number(curModule) === 7 && currentQuestionIndex == '4') {
        <img src={table} alt="table" />;
        const firstQuestion = questions.slice(0, 2);
        const thirdQeustions = questions
          .slice(3, questions.length)
          .map((each, idx) => {
            return <div key={idx}> {each}</div>;
          });
        return (
          <div>
            <div>{firstQuestion[0]} </div>
            <div className="mt-2">{firstQuestion[1]} </div>
            <img
              style={{ objectFit: 'contain', width: '90%' }}
              src={table}
              alt="table"
            />
            <div>{thirdQeustions}</div>
          </div>
        );
      } else {
        return questions.map((each, idx) => {
          return <div key={idx}> {each}</div>;
        });
      }
    } else {
      return question;
    }
  };

  // console.log(
  //   'questions[currentQuestionIndex]',
  //   questions[currentQuestionIndex]
  // );
  // console.log('completePrevious', completePrevious);

  return (
    <div className="mt-4" style={{ overflowX: 'auto' }}>
      <TopCrumb module={module} type="Assessment" />
      <div className="margin-big-width">
        {completePrevious ? (
          <Result status="warning" title={completePrevious} />
        ) : (
          questions.length > 0 &&
          currentQuestionIndex < questions.length && (
            <div>
              <div style={{ overflow: 'hidden' }} className="questions-bg my-5">
                <div>
                  <div>
                    <div className="question-text d-flex">
                      <div
                        className="question-tag align-self-start me-2 fw-bold"
                        style={{ minWidth: '50px' }}
                      >
                        Q{currentQuestionIndex + 1}
                      </div>
                      <div>
                        {renderQuestion(
                          questions[currentQuestionIndex].question
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="options" style={{ marginTop: '20px' }}>
                    {questions[currentQuestionIndex].answer
                      .sort((a, b) => a.id - b.id)
                      .map((answer) => {
                        // console.log('answer', answer);
                        const getCorrectAns = questions[
                          currentQuestionIndex
                        ].answer.find((each) => each.is_correct === '1');
                        return (
                          <div key={answer.id} className="ms-3">
                            <label>
                              <div className="d-flex">
                                <input
                                  className="align-self-start"
                                  style={{ marginTop: '7px' }}
                                  type="radio"
                                  name="answer"
                                  value={answer.id}
                                  defaultChecked={
                                    getCorrectAns.id === answer.id
                                  }
                                  readOnly
                                />
                                <div style={{ textAlign: 'start' }}>
                                  {answer.answer}
                                </div>
                              </div>
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-center">
                <button
                  className="btn btn-radius btn-yellow-bg px-4 font-weight-boldest dark-blue-title btn-next"
                  onClick={handlePreviousQuestion}
                >
                  Previous{' '}
                  <i
                    className="spinner-next fa fa-spinner fa-spin"
                    style={{ display: 'none' }}
                  ></i>
                </button>
                <div className="mx-3"></div>
                <button
                  className="btn btn-radius btn-yellow-bg px-4 font-weight-boldest dark-blue-title btn-next"
                  onClick={handleNextQuestion}
                >
                  NEXT{' '}
                  <i
                    className="spinner-next fa fa-spinner fa-spin"
                    style={{ display: 'none' }}
                  ></i>
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default AssessmentAnswer;
