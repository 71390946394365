import React, { useState, useEffect } from 'react';
import olcBanner from '../assets/olc_banner_no_logo.png';
import Header from '../components/stickyHeader';
// import videoGuide from '../assets/videoGuide.png';
import About1 from '../assets/About/1.png';
import About2 from '../assets/About/2.png';
import About3 from '../assets/About/3.png';
import About4 from '../assets/About/4.png';
import About5 from '../assets/About/5.png';
import About6 from '../assets/About/6.png';
import About7 from '../assets/About/7.png';
import About8 from '../assets/About/8.png';
import About9 from '../assets/About/9.png';
import About10 from '../assets/About/10.png';
import About11 from '../assets/About/11.png';
import About12 from '../assets/About/12.png';
import About13 from '../assets/About/13.png';
import About14 from '../assets/About/14.png';
import About15 from '../assets/About/15.png';
import About16 from '../assets/About/16.png';
import userApi from '../services/userActions';
import { message } from 'antd';
import questionApi from '../services/questionActions';
import ModuleIncomplete from '../components/ModuleIncomplete';

import videoAbout from '../assets/About/dlp-about-video.mp4';
import video2 from '../assets/About/about-video2.mp4';
import { useSelector } from 'react-redux';

import ReactPlayer from 'react-player';

import one from '../assets/About/one.png';
import two from '../assets/About/two.png';
import three from '../assets/About/three.png';

import preview from '../assets/About/preview-app.png';

function About() {
  const location = window.location.host;
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [completedModule, setCompletedModule] = useState('');

  const [open, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const getCompletedModules = async () => {
      try {
        const res = await questionApi.getModuleCompletion();
        if (res) {
          const allCompleted = res.data.allCompleted;
          const allCompletedBefore2023 = res.data.allCompletedBefore2023;

          if (allCompleted) {
            if (!allCompletedBefore2023) {
              setCompletedModule(true);
            }
          }
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    getCompletedModules();
  }, []);

  const registerUser = async () => {
    setLoading(true);
    try {
      if (completedModule) {
        // console.log('user', user);
        const addedUser = await userApi.registerDoctorAtDlp(user);
        if (addedUser) {
          setLoading(false);
          message.success('You have been registered at DLP');
          window.location.href = location.includes('onlinecourse.mydlp.my')
            ? 'https://lifestylechange.mydlp.my/'
            : 'https://dlp.thedgroup.com.my/';
        }
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      // console.log('error', error.response?.data.error);
      if (
        error.response?.data.error === 'Sorry, This user has been registered.'
      ) {
        window.location.href = location.includes('onlinecourse.mydlp.my')
          ? 'https://lifestylechange.mydlp.my/'
          : 'https://dlp.thedgroup.com.my/';
      } else {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    }
  };

  return (
    <div
      style={{
        color: 'white',
        fontSize: '18px',
      }}
      className="text-center main-container"
    >
      <ModuleIncomplete
        isModalOpen={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalOpen={setIsModalOpen}
      />
      <Header />
      <img className="olc-banner" src={olcBanner} alt="banner" />

      <div className="mx-3">
        <div>
          <div className="about-title thirty-width mt-5">WHAT IS DLP ?​</div>
          <div className="about-subtext mt-3">
            A digital platform with patient education and support system that
            brings healthcare professionals and
            <br />
            patient together in making diabetes lifestyle changes.
          </div>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <div>
              <img className="about-img" src={About1} alt="img" />
              <div>Developed by DLP Expert panel</div>
            </div>
            <div>
              <img className="about-img" src={About2} alt="img" />
              <div>Specially designed for people with Type 2 </div>
              <div>Diabetes</div>
            </div>
            <div>
              <img className="about-img" src={About3} alt="img" />
              <div>Supports your patients to develop small but</div>
              <div>impactful healthy habits</div>
            </div>
          </div>
        </div>

        <div>
          <div className="about-title thirty-width mt-5">WHY JOIN DLP ?</div>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <div>
              <img className="about-img" src={About4} alt="img" />
              <div>Diabetes lifestyle training </div>
              <div>from top experts</div>
            </div>
            <div>
              <img className="about-img" src={About5} alt="img" />
              <div>Access to credible resources</div>
            </div>
            <div>
              <img className="about-img" src={About6} alt="img" />
              <div>Certificate Upon Completion </div>
              <div>of Training</div>
            </div>
            <div>
              <img className="about-img" src={About7} alt="img" />
              <div>Virtual Dietitian Support​</div>
            </div>
          </div>
        </div>

        <div>
          <div className="about-title mt-5 fifty-width">
            HELP YOUR PATIENTS TRANSFORM IN 12 WEEKS​
          </div>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <div>
              <img className="about-img" src={About8} alt="img" />
              <div>Receive diabetes lifestyle </div>
              <div>training from top experts</div>
            </div>
            <div>
              <img className="about-img" src={About9} alt="img" />
              <div>
                Educational modules with interactive
                <div>quizzes, bite-sized videos & fun</div>
                <div> activities on Diabetes Lifestyle </div>
              </div>
            </div>
            <div>
              <img className="about-img" src={About10} alt="img" />
              <div>Personalised advice from </div>
              <div>virtual dietitians</div>
            </div>
            <div>
              <img className="about-img" src={About11} alt="img" />
              <div>Close monitoring & support from</div>
              <div>you as their healthcare provider</div>
            </div>
          </div>
        </div>

        <div>
          <div className="about-title mt-5 fifty-width px-3">
            PATIENT'S CRITERIA THAT YOU COULD ENROL INTO DLP
          </div>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <div>
              <img className="about-img" src={About12} alt="img" />
              <div>{`Diagnosed with T2DM <5 `}</div>
              <div>years</div>
            </div>
            <div>
              <img className="about-img" src={About13} alt="img" />
              <div>Age: 18 to 59 years old</div>
            </div>
            <div>
              <img className="about-img" src={About14} alt="img" />
              <div>NOT on insulin therapy</div>
            </div>
            <div>
              <img className="about-img" src={About15} alt="img" />
              <div>Willing to make lifestyle </div>
              <div>changes</div>
            </div>
            <div>
              <img className="about-img" src={About16} alt="img" />
              <div>Technology literate</div>
            </div>
          </div>
        </div>
        <div className="my-5 text-italic">
          <small>
            <i>
              Note: Criteria is for guidance on suitable patients. However, this
              should be at your discretion.
            </i>
          </small>
        </div>
        <div>
          <div
            className="about-title text-uppercase seventy-width  px-3"
            style={{ borderRadius: '30px' }}
          >
            Be a Diabetes Lifestyle Advocate today by completing the training
            modules and register on DLP web-based app.
          </div>
          <div className="mt-5 mx-4">
            <div className="d-flex flex-column flex-md-row justify-content-around">
              <div className="mx-4">
                <div className="text-start">
                  <img style={{ width: '50px' }} src={one} alt="one" />
                </div>

                <div style={{ width: '100%' }} className="mt-3">
                  <ReactPlayer
                    controls={true}
                    url={videoAbout}
                    width="100%"
                    height="100%"
                  />
                </div>

                <div>
                  <div> Watch this step-by-step guide </div>
                  <div> video to sign up and help</div>
                  <div> transform your patient in 12 weeks.</div>
                </div>
              </div>

              <div className="mx-4">
                <div className="text-start">
                  <img style={{ width: '50px' }} src={two} alt="two" />
                </div>
                <div style={{ width: '100%' }} className="mt-3">
                  <ReactPlayer
                    controls={true}
                    url={video2}
                    width="100%"
                    height="100%"
                  />
                </div>

                <div>
                  <div> Refer the guide here to recruit and </div>
                  <div> assist your patient in following the </div>
                  <div> modules and transform in 12 weeks.</div>
                </div>
              </div>
              <div className="mx-5">
                <div className="text-start">
                  <img style={{ width: '50px' }} src={three} alt="three" />
                </div>

                <a href="https://previewcontent.mydlp.my/">
                  <img
                    src={preview}
                    alt="hint"
                    style={{
                      objectFit: 'contain',
                      height: 'auto',
                    }}
                  />
                </a>

                <div>
                  <a
                    href="https://previewcontent.mydlp.my/"
                    style={{ textDecoration: 'none', color: '#fff' }}
                  >
                    Click here to preview the patient’s modules!
                  </a>
                </div>
              </div>
            </div>
          </div>
          {completedModule ? (
            <div className="text-center pb-5 mt-4">
              <button
                className="custom-button mt-5"
                style={{
                  background: '#1B2E48',
                  padding: '5px 2rem',
                  color: '#fff',
                }}
                onClick={() => registerUser(true)}
              >
                Join Now!
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default About;
