// Setup config/headers and token
export const returnError = (error) => {
  // console.log('error print here', error.response);
  if (error.response) {
    if (error.response.data) {
      // console.log('error.response.data', error.response.data);
      if (error.response.data.error === 'Token expired, please login again') {
        return;
      } else {
        return error.response.data.error;
      }
    }
  } else {
    return 'Internal Error';
  }
  const errorMsg =
    error.response && error.response.data
      ? error.response.data.error
      : 'Internal Error';
  return errorMsg;
};

const tokenConfig = (multipart) => {
  const token = localStorage.getItem('dlp-hcp-doctor-token');
  // console.log('token', token)
  // Headers
  const config = {
    headers: {
      'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
    },
  };
  //if there is a token then add it to the header
  if (token) {
    // operator token
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const headerConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

const checkModuleCompletion = (
  completedModules,
  setNotCompleted,
  setCompletedModule,
  module,
  setRetake,
  user
) => {
  if (completedModules.length) {
    const currentModuleId = module.id;
    // step 1  check video is watched or not
    const isVideoCompleted = completedModules.find(
      (completedModule) =>
        completedModule.course_id == currentModuleId &&
        completedModule.status === '1'
    );
    if (isVideoCompleted) {
      setNotCompleted('');
    }

    if (!isVideoCompleted) {
      return setNotCompleted('Please complete the video.');
    }

    const isAssessmentCompleted = completedModules.find(
      (ans) => ans.course_id == currentModuleId && ans.assessment_status === '1'
    );
    // console.log('isAssessmentCompleted', isAssessmentCompleted);
    if (
      isAssessmentCompleted &&
      !isAssessmentCompleted.retake_assessment &&
      user.can_redo &&
      new Date(isAssessmentCompleted.updated_at) < new Date('2024-01-01')
    ) {
      if (isAssessmentCompleted.retake_video_at) {
        return setRetake(true);
      } else {
        return setNotCompleted(
          'Please redo the video to retake the assessment.'
        );
      }
    }

    // step 4 if assessment is completed then show completed assessment message
    if (
      (isAssessmentCompleted && isAssessmentCompleted.retake_assessment) ||
      isAssessmentCompleted
    ) {
      setNotCompleted('');
      return setCompletedModule('You have already completed this module.');
    }
  } else {
    return setNotCompleted('Please complete the video.');
  }
};

const certificateCheckModuleCompletion = (
  completedModules,
  module,
  setNotCompletedModule,
  setCompletedModule
) => {
  if (completedModules.length) {
    const currentModuleId = module.id;
    // step 1  check video is watched or not
    const isVideoCompleted = completedModules.filter(
      (completedModule) =>
        completedModule.course_id == currentModuleId &&
        completedModule.status === '1'
    );

    // step 2  check this assessment is answered or not
    const isAssessmentCompleted = completedModules.find(
      (completedModule) =>
        completedModule.course_id == currentModuleId &&
        completedModule.assessment_status === '1'
    );

    if (isVideoCompleted && isAssessmentCompleted) {
      setNotCompletedModule('');
      setCompletedModule(isAssessmentCompleted);
    }

    // step 3 if assessment is completed then show certificate
    if (!isVideoCompleted || !isAssessmentCompleted) {
      return setNotCompletedModule('Please complete video and assessment.');
    }
  } else {
    return setNotCompletedModule('Please complete video and assessment.');
  }
};

// const certificateCheckModuleCompletion = (
//   completedModules,
//   module,
//   setCompletePrevious,
//   setCompletedModule,
//   curModule
// ) => {
//   if (completedModules.length) {
//     setCompletePrevious('');
//     // Check if assessment is completed for the current course
//     const currentModuleId = module.id;
//     // step 1  check video is watched or not
//     const isVideoCompleted = completedModules.filter(
//       (completedModule) =>
//         completedModule.course_id == currentModuleId &&
//         completedModule.status === '1'
//     );

//     if (!isVideoCompleted.length) {
//       return setCompletePrevious('Please complete the video.');
//     }

//     // step 2  check this assessment is answered or not
//     const isAssessmentCompleted = completedModules.find(
//       (completedModule) =>
//         completedModule.course_id == currentModuleId &&
//         completedModule.assessment_status === '1'
//     );

//     // step 3 if assessment is completed then show certificate
//     if (isAssessmentCompleted) {
//       return setCompletedModule(isAssessmentCompleted);
//     }

//     const latestCompletedModule = completedModules.sort(
//       (a, b) => b.course_id - a.course_id
//     );

//     // step 4 if latest module completed is just one module before then allow to take the assessment
//     const latestModCompleted = Number(latestCompletedModule[0].course_id);

//     if (currentModuleId == latestModCompleted + 1) {
//       return setCompletePrevious('Please complete the assessment.');
//     } else {
//       // step 5 if latest module completed is not just one module before then don't allow to take the assessment
//       // eg. current access module is 5 , latest completed module is 3
//       return setCompletePrevious('Please complete previous module.');
//     }
//   } else {
//     if (curModule == 1) {
//       return setCompletePrevious('Please complete the assessment.');
//       //step 6 if current module is 1 only allow to take the assessment
//     }
//     if (curModule > 1) {
//       // step 7 if current module is above 1 don't allow to take the assessment
//       return setCompletePrevious('Please complete previous module.');
//     }
//   }
// };

// const checkModuleCompletion = (
//   completedModules,
//   setNotCompleted,
//   setCompletePrevious,
//   setCompletedModule,
//   curModule,
//   module
// ) => {
//   if (completedModules.length) {
//     setCompletePrevious('');
//     // Check if assessment is completed for the current course
//     const currentModuleId = module.id;
//     // step 1  check video is watched or not
//     const isVideoCompleted = completedModules.filter(
//       (completedModule) =>
//         completedModule.course_id == currentModuleId &&
//         completedModule.status === '1'
//     );

//     // console.log('isVideoCompleted', isVideoCompleted);

//     if (!isVideoCompleted.length) {
//       return setCompletePrevious('Please complete the video.');
//     }

//     // step 2  check this assessment is answered or not
//     const isAssessmentCompleted = completedModules.find(
//       (ans) => ans.course_id == currentModuleId && ans.assessment_status === '1'
//     );

//     // step 3  if assessment is not completed for the current then allow to take the assessment
//     if (isVideoCompleted && !isAssessmentCompleted) {
//       return setNotCompleted(true);
//     }

//     // step 4 if assessment is completed then show completed assessment message
//     if (isAssessmentCompleted) {
//       return setCompletedModule('You have already completed this module.');
//     }

//     // step 5 if latest module completed is just one module before then allow to take the assessment
//     const latestCompletedModule = completedModules.sort(
//       (a, b) => b.course_id - a.course_id
//     );

//     const latestModCompleted = Number(latestCompletedModule[0].course_id);
//     if (currentModuleId == latestModCompleted + 1) {
//       return setNotCompleted(true);
//     } else {
//       // step 6 if latest module completed is not just one module before then don't allow to take the assessment
//       // eg. current access module is 5 , latest completed module is 3
//       return setCompletePrevious('Please complete previous module.');
//     }
//   } else {
//     if (curModule == 1) {
//       return setNotCompleted(true);
//       //step 7 if current module is 1 only allow to take the assessment
//     }
//     if (curModule > 1) {
//       // step 8 if current module is above 1 don't allow to take the assessment
//       return setCompletePrevious('Please complete previous module.');
//     }
//   }
// };

export {
  tokenConfig,
  headerConfig,
  checkModuleCompletion,
  certificateCheckModuleCompletion,
};
